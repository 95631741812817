<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Panel : {{ this.details.code }} : {{ this.details.name }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <b-tabs card>

            <b-tab title="Panel" class="active mt-2">

              <table class="table" v-if="details">
                <tbody>
                    <tr v-for="(v, k, index) in details" :key="index"  v-if="k !== 'id' && k !== 'product' && k !== 'insurers' && k !== 'commission'">
                      <th v-if="k === 'pretty_insurers'">Insurers</th>
                      <th v-else >{{ k|capitalize }}</th>
                      <td colspan="2" v-if="k === 'pretty_insurers'">
                        <span v-for="(insurer, index) in v" :key="index" class="mr-2" >{{insurer.short_name}}</span>
                      </td>
                      <td colspan="2" v-else-if="k === 'active'">
                        <i v-if="v === true || v === 'true'" class="fas fa-check text-success"></i>
                        <i v-else class="fas fa-times text-danger"></i>
                      </td>
                      <td colspan="2" v-else v-html="v">{{ v }}</td>
                    </tr>
                </tbody>

              </table>

            </b-tab>

            <b-tab title="Commission" class="mt-2" >

              <table class="table">
                <tbody>
                    <tr>
                      <th>%</th>
                      <th>CSID</th>
                      <th>Insurers</th>
                    </tr>
                    <tr v-for="(commission, index) in details.commission" :key="index">
                      <td>{{ commission.percent }}</td>
                      <td>{{ commission.csid }}</td>
                      <td colspan="2" >
                        <span v-for="(insurer, index) in commission.pretty_insurers" :key="index" class="mr-2" >{{insurer.short_name}}</span>
                      </td>

                    </tr>
                </tbody>
              </table>

            </b-tab>

          </b-tabs>

        </b-card>


        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button v-show="hasFullAccess" :to="{ name: 'PanelEdit', params: { product: this.$route.params.product, id: this.$route.params.id } }" variant="outline-dark" class="float-right">Edit</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";

  export default {
    name: 'Panel',
    components: {Id},
    props: {
      caption: {
        type: String,
        default: 'Panel Details'
      },
    },
    data: () => {
      return {
        details: {
         code: "",
         name: "",
         description: "",
         insurers: "",
         active: "",
         commission: []
        },
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getData() {

        let url = '/commission/panels/' + this.$route.params.id +  '/?product=' + this.$route.params.product;

        axios.get(url).then(
          response => {
            this.details = response.data
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('email setting error');
            console.log(error.response)
          })
        return this.details
      }
    },
    computed: {
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
    },
    mounted() {
      this.getData()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getData()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
